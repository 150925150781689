import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'
import '../assets/scss/main.scss'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      fragment WpSeo on WpPostTypeSEO {
        metaDesc
        metaKeywords
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        schema {
          articleType
          pageType
          raw
        }
        title
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
      }
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wp {
          siteSettings {
            acf {
              address1
              address2
              address3
              telephone
              fax
              email
            }
          }
        }
        allWpTeam(sort: {date: ASC}) {
          edges {
            node {
              id
              title
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header location={location} data={data} />
        <main>{ children }</main>
        <Footer data={data} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
